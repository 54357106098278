import { useCallback, useEffect, useRef, useState } from "react";

import { DataPointApi, latest } from "../api/latest";

export interface DataPoint {
  entrytime: Date;
  temperature: number;
  humidity: number;
  pressure: number;
}

interface DataPoints {
  servertime: Date;
  points: DataPoint[];
}

const raw2Temperature = (raw: number | null) => raw ?? NaN; // / 100.0);
const raw2Humidity = (raw: number | null) => raw ?? NaN; // / 1024.0);
const raw2Pressure = (raw: number | null) => raw ?? NaN; // / 256.0) / 100.0);

const useChartData = () => {
  const [data, setData] = useState<DataPoint[] | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | undefined>(undefined);
  const abortController = useRef<AbortController>();
  const lastDataPointId = useRef(0);

  useEffect(() => {
    return () => {
      abortController.current?.abort();
    };
  }, []);

  const getChartData = useCallback(async (deviceId: number, count: number) => {
    abortController.current?.abort();
    abortController.current = new AbortController();

    setIsLoading(true);
    try {
      const addressesResp = await latest(
        { count, sinceId: lastDataPointId.current, deviceId },
        abortController.current.signal
      );

      let highestId = 0;
      let points = addressesResp.results.map((point) => {
        if (point.id > highestId) highestId = point.id;
        const dataPoint: DataPoint = {
          entrytime: new Date(point.entrytime),
          temperature: raw2Temperature(point.temperature),
          humidity: raw2Humidity(point.humidity),
          pressure: raw2Pressure(point.pressure),
        };
        return dataPoint;
      });

      if (highestId === 0 || highestId === lastDataPointId.current) return;

      lastDataPointId.current = highestId;

      setData((prev) => {
        //console.log(prev);
        if (prev) points = prev.concat(points);
        points = points.slice(-1440 + 1);
        return points;
      });

      setError(undefined);
      return true;
    } catch (ex) {
      setError(ex as Error);
      return false;
    } finally {
      setIsLoading(false);
    }
  }, []);

  return { data, getChartData, isLoading, error };
};

export default useChartData;
