import { Filler } from "chart.js";
import "chartjs-adapter-date-fns";
import ChartDataLabels from "chartjs-plugin-datalabels";

export const options = {
  stacked: false,
  elements: {
    line: {
      stepped: true,
    },
  },
  scales: {
    y: {
      beginAtZero: true,
      min: 15,
      max: 30,
      display: true,
      stack: "demo",
      stackWeight: 1,
    },
    y1: {
      beginAtZero: true,
      min: 30,
      max: 60,
      display: true,
      //position: 'right',
      stack: "demo",
      stackWeight: 1,
    },
    y2: {
      beginAtZero: true,
      min: 980,
      max: 1036,
      display: true,
      //position: 'right',
      stack: "demo",
      stackWeight: 1,
    },
    x: {
      //display: false,
      type: "time",
      time: {
        unit: "minute",
        displayFormats: {
          minute: "HH:mm",
        },
      },
    },
  },
  plugins: {
    datalabels: {
      backgroundColor: function (context: any) {
        return context.dataset.backgroundColor;
      },
      borderRadius: 4,
      color: "white",
      font: {
        weight: "bold",
      },
      formatter: Math.round,
      padding: 6,
      display: (context: any) => context.dataIndex % 60 === 0,
    },
    filler: {
      propagate: false,
    },
    title: {
      display: true,
      text: "abc",
    },
    legend: {
      display: false,
    },
    tooltip: {
      mode: "index" as const,
      intersect: false,
    },
    crosshair: {
      display: false,
    },
    /*crosshair: {
		line: {
			color: '#F66',  // crosshair line color
			width: 1        // crosshair line width
		},
		sync: {
			enabled: true,            // enable trace line syncing with other charts
			group: 1,                 // chart group
			suppressTooltips: false   // suppress tooltips when showing a synced tracer
		},
		zoom: {
			enabled: true,                                      // enable zooming
			zoomboxBackgroundColor: 'rgba(66,133,244,0.2)',     // background color of zoom box 
			zoomboxBorderColor: '#48F',                         // border color of zoom box
			zoomButtonText: 'Reset Zoom',                       // reset zoom button text
			zoomButtonClass: 'reset-zoom',                      // reset zoom button class
		},
		callbacks: {
		//beforeZoom: () => function(start, end) {                  // called before zoom, return false to prevent zoom
		//return true;
		//},
		//afterZoom: () => function(start, end) {                   // called after zoom
		//}
		}
		}*/
  },
  //interaction: {
  //	mode: 'index',
  //	intersect: false,
  //},
  radius: 0,
  aspectRatio: 3,
  //events: [], // Disable mouse hover stuff
  responsive: true,
  maintainAspectRatio: false,
} as const;
/*
const labels: (number | string)[] = [];
for(let i = 0; i< DATA_POINTS;i++)
	labels.push(Math.floor(Date.now() + (i * 6000)));
*/
const skipped = (ctx: any, value: any) =>
  ctx.p0.skip || ctx.p1.skip ? value : undefined;
const down = (ctx: any, value: any) =>
  ctx.p0.parsed.y > ctx.p1.parsed.y ? value : undefined;

export const createDataObj = () => {
  const now = new Date();

  let today = new Date();
  today.setSeconds(0);
  today.setMilliseconds(0);
  today.setHours(0);
  today.setMinutes(0);

  let myDate = new Date();
  myDate.setSeconds(0);
  myDate.setMilliseconds(0);
  myDate = new Date(myDate.getTime() - 24 * 60 * 60 * 1000);
  myDate = new Date(myDate.getTime() + myDate.getTimezoneOffset() * 60 * 1000); // timezone

  const labels: Date[] = [];
  for (let i = 0; i < 1440; i++) {
    labels.push(myDate);
    myDate = new Date(myDate.getTime() + 60 * 1000);
  }

  const data = {
    labels, //: [] as string[],
    datasets: [
      {
        label: "Temperature",
        data: [] as number[],
        borderWidth: 2,
        borderColor: "#ff6e00", //style.getPropertyValue("--chart-line"),
        backgroundColor: "rgb(255, 110, 0, 0.2)", //style.getPropertyValue("--chart-area"),
        fill: "start",
        yAxisID: "y",
        stepped: true,
        datalabels: {
          align: "start" as const,
          anchor: "start" as const,
          offset: 10 as const,
          formatter: function (value: any, context: any) {
            return value.toFixed(1);
          },
        },
        segment: {
          borderColor: (ctx: any) =>
            ctx.p0.parsed.y < 21 ? "rgb(128,200,255)" : undefined,
          backgroundColor: (ctx: any) =>
            ctx.chart.data.labels[ctx.p0DataIndex].valueOf() < today.valueOf()
              ? "rgb(255, 110, 0, 0.1)"
              : undefined,
          //borderDash: (ctx: any) => skipped(ctx, [6, 6]),
        },
      },
      {
        label: "Humidity",
        data: [] as number[],
        borderWidth: 2,
        borderColor: "#ff9913", //style.getPropertyValue("--chart-line"),
        backgroundColor: "rgb(255, 153, 19, 0.2)", //style.getPropertyValue("--chart-area"),
        fill: "start",
        yAxisID: "y1",
        stepped: true,
        datalabels: {
          align: "start" as const,
          anchor: "start" as const,
          offset: 10 as const,
          formatter: function (value: any, context: any) {
            return value.toFixed(1);
          },
        },
        segment: {
          borderColor: (ctx: any) =>
            ctx.p0.parsed.y > 50 || ctx.datasetIndex > 100
              ? "rgb(255,0,0)"
              : undefined,
          backgroundColor: (ctx: any) =>
            ctx.chart.data.labels[ctx.p0DataIndex].valueOf() < today.valueOf()
              ? "rgb(255, 153, 19, 0.1)"
              : undefined,
          //borderDash: (ctx: any) => skipped(ctx, [6, 6]),
        },
      },
      {
        label: "Pressure",
        data: [] as number[],
        borderWidth: 2,
        borderColor: "#ffbd31",
        backgroundColor: "rgb(255, 189, 49, 0.2)", //style.getPropertyValue("--chart-area"),
        fill: "start",
        yAxisID: "y2",
        stepped: true,
        datalabels: {
          align: "start" as const,
          anchor: "start" as const,
          offset: 10 as const,
          formatter: function (value: any, context: any) {
            return value.toFixed(1);
          },
        },
        segment: {
          backgroundColor: (ctx: any) =>
            ctx.chart.data.labels[ctx.p0DataIndex].valueOf() < today.valueOf()
              ? "rgb(255, 189, 49, 0.1)"
              : undefined,
        },
      },
    ],
  };
  return data;
};

export const plugins = [
  Filler,
  ChartDataLabels,
  {
    id: "blah",
    afterDraw: (chart: any) => {
      const ctx = chart.ctx;
      if (!ctx) return;
      const used = chart.data.datasets[0].data[0];
      ctx.save();
      ctx.fillStyle = "white";
      ctx.textAlign = "right";
      ctx.fillText("TEST " + used, 150, 50);
      //ctx.restore();

      if (chart.tooltip?._active?.length) {
        let x = chart.tooltip._active[0].element.x;
        let yAxis = chart.scales.y;
        //let y1Axis = chart.scales.y1;
        let y2Axis = chart.scales.y2;
        let ctx = chart.ctx;
        //ctx.save();
        ctx.beginPath();
        ctx.moveTo(x, y2Axis.top);
        ctx.lineTo(x, yAxis.bottom);
        ctx.lineWidth = 2;
        ctx.strokeStyle = "rgba(0, 255, 0, 0.4)";
        ctx.stroke();
      }

      ctx.restore();
    },
  },
];
