import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { DataPointApi } from "~/api/latest";
import { createDataObj, options, plugins } from "./util";
import { DataPoint } from "~/hooks/useChartData";

const DATA_POINTS = 1440;

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
);

interface TemperatureProps {
  data: DataPoint[] | undefined;
}

const Temperature: React.FC<TemperatureProps> = ({ data: data2 }) => {
  const [chartData, setChartData] = useState(createDataObj());

  useEffect(() => {
    if (data2 === undefined) return;

    setChartData((prev) => {
      //const newDataLen = data2.length;
      //const removeCount = Math.min(newDataLen, DATA_POINTS);

      const a = createDataObj();
      //a.labels = prev.labels;//.slice(-removeCount);
      //a.datasets[0].data = prev.datasets[0].data;//.slice(-removeCount);
      //a.datasets[1].data = prev.datasets[1].data;//.slice(-removeCount);
      //a.datasets[2].data = prev.datasets[2].data;//.slice(-removeCount);

      const timePoints: {
        [key: string]: {
          temperature: number;
          humidity: number;
          pressure: number;
        };
      } = {};

      data2.forEach((val) => {
        const t = new Date(val.entrytime.getTime());
        t.setMilliseconds(0);
        t.setSeconds(0);
        const time = Math.floor(t.valueOf() / 1000);
        timePoints[time] = {
          temperature: val.temperature,
          humidity: val.humidity,
          pressure: val.pressure,
        };
      });

      //console.log(timePoints);
      //console.log(data2);
      //console.log(a.labels);

      a.labels.forEach((val) => {
        //a.labels.push(val.entrytime);
        //console.log(Math.floor((val as unknown as Date).valueOf() / 1000));
        const t = Math.floor(val.valueOf() / 1000);
        const b = timePoints[t];
        if (b) {
          a.datasets[0].data.push(b.temperature);
          a.datasets[1].data.push(b.humidity);
          a.datasets[2].data.push(b.pressure);
        } else {
          a.datasets[0].data.push(NaN);
          a.datasets[1].data.push(NaN);
          a.datasets[2].data.push(NaN);
        }
      });
      /*
			data2.forEach((val: DataPointApi) => {
				//a.labels.push(val.entrytime);
				a.datasets[0].data.push(val.temperature!);
				a.datasets[1].data.push(val.humidity!);
				a.datasets[2].data.push(val.pressure!);
			});
*/
      //a.labels = a.labels.slice(-DATA_POINTS);
      //a.datasets[0].data = a.datasets[0].data.slice(-DATA_POINTS);
      //a.datasets[1].data = a.datasets[1].data.slice(-DATA_POINTS);
      //a.datasets[2].data = a.datasets[2].data.slice(-DATA_POINTS);

      return a;
    });
  }, [data2]);

  //if(!data2) return <>Loading...</>;

  console.log(chartData);

  return <Line options={options} data={chartData} plugins={plugins} />;
};

export default Temperature;
